import React, { useState } from 'react';
import '../../css/Quiz.css'; 
import Bilde from '../../images/clementineQuiz.jpeg';


const Quiz = ({ data }) => {
    const [answeredQuestions, setAnsweredQuestions] = useState({}); // Tracks which questions have been answered
    const [score, setScore] = useState(0); // Keeps track of the user's score
    const [allAnswered, setAllAnswered] = useState(false); // Track if all questions have been answered

    const handleAnswerClick = (questionIndex, answerIndex, isCorrect) => {
        // Prevent further answering of the same question
        if (answeredQuestions[questionIndex]) return;

        // Mark the question as answered
        setAnsweredQuestions((prev) => ({
            ...prev,
            [questionIndex]: {
                answerIndex, // Track which answer was selected
                isCorrect // Track if the selected answer is correct
            },
        }));

        // Update the score if the answer is correct
        if (isCorrect) {
            setScore((prevScore) => prevScore + 1);
        }

        // Check if all questions are answered
        if (Object.keys(answeredQuestions).length + 1 === data.questions.length) {
            setAllAnswered(true);
        }
    };

    return (
        <div className="quiz-container">
            <img src={Bilde} alt="Quiz" className="quiz-image" />

            {data.questions.map((question, questionIndex) => (
                <div key={questionIndex} className="question-block">
                    <h3 className='question-text'>{question.question}</h3>
                    <div className="options-container">
                        {question.options.map((option, answerIndex) => {
                            const isAnswered = answeredQuestions.hasOwnProperty(questionIndex);
                            const selectedAnswerIndex = answeredQuestions[questionIndex]?.answerIndex;
                            const isCorrect = option.isCorrect;
                            const isWrong = !option.isCorrect;

                            return (
                                <button
                                    key={answerIndex}
                                    className={`quiz-option 
                                        ${isAnswered && answerIndex === selectedAnswerIndex && isCorrect ? 'correct' : ''} 
                                        ${isAnswered && answerIndex === selectedAnswerIndex && isWrong ? 'wrong' : ''}`}
                                    onClick={() => handleAnswerClick(questionIndex, answerIndex, isCorrect)}
                                    disabled={isAnswered} // Disable buttons after the question is answered
                                >
                                    {option.answer}
                                </button>
                            );
                        })}
                    </div>

                    {/* Show reason once the question has been answered */}
                    {answeredQuestions[questionIndex] && (
                        <div className="answer-reason">
                            <p>{question.reason}</p>
                        </div>
                    )}
                </div>
            ))}

            {allAnswered && (
                <div className="score-display">
                    <h3>Du fekk {score} av {data.questions.length} klementinspørsmål riktig!</h3>
                </div>
            )}
        </div>
    );
};

export default Quiz;
