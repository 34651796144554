import React, { useState } from 'react';
//import './App.css';
import Header from './components/Header';
import Home from './components/HomePage';
import HighScoreList from './components/HighScoreList';
import AddScoreForm from './components/AddScoreForm';
import Rules from './components/Rules';
import AdventGrid from './components/AdventGrid';
import CalendarPage from './components/CalendarPage';

const App = () => {
    const [view, setView] = useState('home');

    let content;
    switch (view) {
        case 'home':
            content = <Home />;
            break;
        case 'addScore':
            content = <AddScoreForm />;
            break;
        case 'highScores':
            content = <HighScoreList />;
            break;
        case 'rules':
            content = <Rules />;
            break;
        case 'christmasCalendar':
            content = <CalendarPage />;
            break;
        case 'adventGrid':
            content = <AdventGrid />
            break;
        default:
            content = <Home />;
            break;
    }

    return (
        //<div>
        //   <Header setView={setView} />
         //   <div className="content">
        //        {content}
        //    </div>
        // </div>
        <div>
            Klementinsesongen er diverre slutt. Velkomen tilbake i November.
        </div>
    );
};

export default App;

