import React from 'react';
import Bilde from '../../images/clementineFunFact.jpeg'
import '../../css/Funfact.css'; 


const FunFact = ({ data }) => {
    return (
        <div className="fun-fact-container">
            <img src={Bilde} alt="Fun fact" className="funfact-image" />
            <h2>{data.title}</h2>
            <p>{data.content}</p>
        </div>
    );
};

export default FunFact;