import React, { useState, useEffect } from 'react';
import giftsData from '../../data/gifts.json';
import '../../css/MusicPlayer.css';
import Bilde from '../../images/klementinsang.png';

const MusicPlayer = ({ day }) => {
    const [isPlaying, setIsPlaying] = useState(false);
    const [audio, setAudio] = useState(null);
    const [songData, setSongData] = useState({});
    const [currentTime, setCurrentTime] = useState(0);
    const [duration, setDuration] = useState(0);

    const SAS = "?sp=r&st=2024-12-03T09:01:10Z&se=2025-01-11T17:01:10Z&sv=2022-11-02&sr=c&sig=%2FD%2FXqs9D08IVxPSf45CELs1ws%2FxGUv%2FZliEmfzWa%2Ftc%3D";

    useEffect(() => {
        const musicData = giftsData[day]?.type === 'music' ? giftsData[day] : null;
        if (musicData) {
            setSongData(musicData);
            const newAudio = new Audio(musicData.link + SAS);
            setAudio(newAudio);

            newAudio.addEventListener('timeupdate', () => {
                setCurrentTime(newAudio.currentTime);
                setDuration(newAudio.duration);
            });

            // Cleanup function to pause and reset the audio
            return () => {
                newAudio.pause();
                newAudio.currentTime = 0; // Reset playback to the beginning
                newAudio.removeEventListener('timeupdate', () => {
                    setCurrentTime(newAudio.currentTime);
                    setDuration(newAudio.duration);
                });
            };
        }
    }, [day]);

    const handlePlayPause = () => {
        if (isPlaying) {
            audio.pause();
        } else {
            audio.play();
        }
        setIsPlaying(!isPlaying);
    };

    const handleStop = () => {
        if (audio) {
            audio.pause();
            audio.currentTime = 0;
            setIsPlaying(false);
        }
    };

    const handleSliderChange = (event) => {
        if (audio) {
            audio.currentTime = event.target.value;
            setCurrentTime(event.target.value);
        }
    };

    // Cleanup when the component unmounts
    useEffect(() => {
        return () => {
            if (audio) {
                audio.pause();
                audio.currentTime = 0;
            }
        };
    }, [audio]);

    return (
        <div className="music-player-container">
            <img src={Bilde} alt="Musikk" className="musikk-image" />
            <h2>{songData.songName}</h2>
            <p>{songData.artist}</p>
            <button onClick={handlePlayPause} className='music-button'>
                {isPlaying ? 'Pause' : 'Play'}
            </button>
            <button onClick={handleStop} className='music-button'>
                Stop
            </button>
            <input
                type="range"
                min="0"
                max={duration}
                value={currentTime}
                onChange={handleSliderChange}
                className="slider"
            />
            <div className="time-display">
                {Math.floor(currentTime / 60)}:{Math.floor(currentTime % 60).toString().padStart(2, '0')} / {Math.floor(duration / 60)}:{Math.floor(duration % 60).toString().padStart(2, '0')}
            </div>
        </div>
    );
};

export default MusicPlayer;
