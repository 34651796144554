import React, { useState } from 'react';
import Gift from './Gift'; // Import your GiftText component
import Bilde from '../images/clementineGift.jpeg'; // Import your image
import '../css/AdventToday.css'; // Assuming you have a separate CSS file for styles

const AdventToday = ({ day, onBackClick, onGiftOpened, isOpened}) => {
    const [opened, setOpened] = useState(isOpened); // Tracks whether the gift is opened
    const [isAnimating, setIsAnimating] = useState(false);


    // Function to handle click and open the gift
    const handleClick = () => {
        if (!opened) {

            setIsAnimating(true); // Start the animation
            setTimeout(() => {
                setOpened(true);
                onGiftOpened(day); // Notify the parent that the gift has been opened
                setIsAnimating(false); // Reset animation state
            }, 300); // Match the duration of the animation (500ms)
        }
    };

    return (
        <div className="calendar-container">
            {!isOpened ? (
                <div>
                    <h2>Luke {day}</h2> {/* Use the day prop here */}
                    <div className='image-container'>
                        <img
                            src={Bilde}
                            alt="Gift"
                            className={`calendar-image ${isAnimating ? 'spin-animation' : ''}`}
                            onClick={handleClick}
                        />
                    </div>
                </div>
            ) : (
                // Once the gift is opened, show the GiftText component
                <div>
                    <Gift day={day} />  {/* Pass the day to GiftText component */}
                </div>
            )}
            <button className="back-button" onClick={onBackClick}>Tilbake</button>

        </div>
    );
};

export default AdventToday;
