import React from 'react';
import Bilde from '../images/clementineGift.jpeg';
import '../css/AdventGrid.css';


const AdventGrid = ({ onDayClick }) => {
    const date = new Date();
    const today = date.getDate(); // Get the current day of the month
    const currentMonth = date.getMonth(); // Get the current month (0-based, so December is 11)

    // For testing, let's assume we're in November (replace 11 with currentMonth if using live date)
    //const testingDay = 11; // Temporary, you can use 'today' when live
    //const testingMonth = 11; // Temporary, will use 'currentMonth' when live

    const images = Array.from({ length: 24 }, (_, i) => i + 1); // Days 1-24

    return (
        <div className='grid-container'>
            <h2 className='advent-title'>Adventskalender</h2>
            <div className="advent-grid">
                
                {images.map((num) => {
                    // Check if the day is in the futuer
                    const isFutureDay = (num > today || currentMonth > 11); // Month check for November and December

                    return (
                        <div key={num} className={`advent-item ${isFutureDay ? 'future-day' : ''}`}>
                            <div
                                className={`image-container ${isFutureDay ? 'disabled' : ''}`}
                                onClick={() => !isFutureDay && onDayClick(num)} // Only allow clicks on past or present days
                            >
                                <img
                                    src={Bilde}
                                    alt={`Luke ${num}`}
                                    className="advent-image"
                                />
                                <div className={`overlay-number ${isFutureDay ? 'dark-overlay' : ''}`}>
                                    {num}
                                </div>
                            </div>
                        </div>
                    );
                })}
            </div>
        </div>
    );
};

export default AdventGrid;