import React from 'react';
import '../css/Rules.css';

const Rules = () => {
    return (
        <div className="rules-container">
            <h2>Reglar og meir</h2>
            <p>Hei alle sitruselskande klementinetarar. No er tida komen. Vi leikar ikkje konkurranse. <br></br>
                Alle klementinar som ikkje følger desse reglane som står på denne sida, vil verte umyndiggjort, og kasta på havet som gårsdagens fisk. <br></br>
                Konkurransen er berre for bruk internt, så ikkje del nettsida med utanforståande. <br></br>
                Så hent lommeboka, ta på hanskar og kast loss! Lat jakta på den perfekte klementinen starte. 
                <br></br>Hiv og hoi!</p>

            <div className="rules-section">
                <h3>Generelle retningslinjer</h3>
                <p></p>
                <ul>
                    <li>Vinnaren vil få premie tilsendt i posten. Kanskje.</li>
                    <li>Legg inn antal båtar du finn i ei klementin på "Legg inn klementin"-fana øvst på sida.</li>
                    <li>Et mandarinen du skreller. Kvar einaste båt. Dei små, ekle, kvite strekane kan kastast i komposten saman med skalet. </li>
                    <li>Vi samlar inn all statistikk, så sjølv om du ikkje har rekord-klementin, send den inn! På sikt vil vi også få ei botnliste, så et i veg!</li>
                    <li>Finn du mot formodning ein rekordkandidat (12 eller fleire båtar) så må klementinen verifiserast. 
                        Dette kan gjerast ved å sende bilete til <a href="mailto:mathias@klementinbaat.no">mathias@klementinbåt.no </a>  
                         eller <a href="mailto:amund@klementinbaat.no">amund@klementinbåt.no</a>. 
                         Bilete må innehalde klementinen med alle båtane godt synlege og eit eksemplar av dagens avis med dagens dato. </li>
                </ul>
            </div>

            <div className="rules-section">
                <h3>Kva er ein båt?</h3>
                <p>Nynorskordboka definerer ein båt som: liten farkost til å ro eller til å drive fram med segl eller motor. <br></br>
                    Ein dårleg definisjon. Heldigvis inneheld Nynorskordboka også ein annan definisjon på båt: noko som i forma liknar på ein båt. 
                    <br></br>
                    Her kjem reglane for korleis du tel båtar i denne konkurransen.
                    </p>
                <ul>
                    <li>Vi delar båtar i to kategoriar: Små og vanlige båtar. Små båtar er båtar som er mindre enn radiusen til klementinen. Dersom klementinen ikkje er heilt rund, er det den minste radiusen som teller. Altså den minste avstanden frå senteret av klementinen til skalet. Alt som er større enn dette definerast som vanlige båtar. </li>
                    <li>For vanlege båtar må ein kun kunne separere dei enkelt, og det må vere hud på kvar side av båten. Det er tillaten med små rifter på vanlege båtar, medan store hol i huda slik at ein ser kjøt, vil ikkje vere lov. Då høyrer den båten til den båten som den kom frå, og dei to bitane til saman utgjer ein båt.</li>
                    <li>For små båtar er reglane litt strengare. Her tillet vi ingen hol i det heile tatt, kun små rifter som er mindre enn 0,1 mm breie er tillatne. Er rifta over heile sida på båten, så er båten ikkje godkjend.</li>
                </ul>
            </div>

            <div className="rules-section">
                <h3>Anna info</h3>
                <ul>
                    <li>Konkurransen varar så lenge det er klementinsesong. </li>
                    <li>For meir informasjon om klementin, og kva forskjellen på klementin og mandarin er, sjekk denne nyheitsartikkelen frå NRK: <a href="https://www.nrk.no/nordland/klementin-eller-mandarin-til-jul-_-her-har-du-svaret.-1.15746973">NRK Nyhende</a>. I tillegg til spennande informasjon, så er det også ein kjekk kviss på slutten. Klarar du 5 av 5?</li>
                </ul>
            </div>

        </div>
    );
};

export default Rules;
