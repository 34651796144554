// src/Header.js
import React from 'react';
import '../css/Header.css';


const Header = ({ setView }) => {
    return (
        <header className="header">
            <nav className="nav">
                <button onClick={() => setView('home')} className="nav-link">Heim</button>
                <button onClick={() => setView('rules')} className="nav-link">Reglar</button>
                <button onClick={() => setView('addScore')} className="nav-link">Legg inn klementin</button>
                <button onClick={() => setView('highScores')} className="nav-link">Toppliste</button>
                <button onClick={() => setView('christmasCalendar')} className="nav-link">Adventskalender</button>
           

            </nav>
        </header>
    );
};

export default Header;