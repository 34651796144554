import React, { useEffect, useState } from 'react';
import Score from './Score'; // Import the Score component
import '../css/HighScoreList.css';


async function fetchScoresFromDb(functionUrl, functionKey, setScores, cacheKey, setError) {
    const response = await fetch(functionUrl, {
        method: 'GET',
        headers: {
            'Content-Type': 'application/json',
            'x-functions-key': functionKey,
        }
    });

    if (response.ok) {
        const data = await response.json();
        const sortedScores = data.sort((a, b) => {
            const scoreComparison = b.score - a.score;
            if (scoreComparison === 0) {
                const dateA = new Date(a.timestamp);
                const dateB = new Date(b.timestamp);
                return dateA - dateB; // Oldest first
            }
            return scoreComparison;
        });
        setScores(sortedScores);
        console.log("Fetching high scores from DB");
        localStorage.setItem(cacheKey, JSON.stringify(sortedScores));
        localStorage.setItem(`${cacheKey}_time`, Date.now());
    } else {
        setError('Failed to fetch scores.');
    }
}



const HighScoreList = () => {
    const [scores, setScores] = useState([]);
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(null);

    const cacheKey = 'highScoresCache';
    const cacheExpiry = 60 * 60 * 1000; // Cache expiry time in milliseconds (e.g., 1 hour)

    const functionUrl = ''; //TODO: Find proper url and key
    const functionKey = '';

    useEffect(() => {
        const fetchScores = async () => {
            try {
                const cachedData = localStorage.getItem(cacheKey);
                const cachedTime = localStorage.getItem(`${cacheKey}_time`);

                if (cachedData && cachedTime && (Date.now() - cachedTime) < cacheExpiry) {
                    setScores(JSON.parse(cachedData));
                    console.log("Fetching highscores from cache");
                } else {
                    await fetchScoresFromDb(functionUrl, functionKey, setScores, cacheKey, setError);
                }
            } catch (error) {
                setError('Noko uforutset har skjedd!');
                console.log(error.message);
            } finally {
                setLoading(false);
            }
        };

        fetchScores();
        // eslint-disable-next-line    
    }, []);

    if (loading) {
        return <p>Leitar etter klementinar...</p>;
    }

    if (error) {
        return <p>{error}</p>;
    }

    const handleRefreshClick = async () => {
        await fetchScoresFromDb(functionUrl, functionKey, setScores, cacheKey, setError);
    };

    return (
        <div className="highscore-list">
            <h2>Flest båtar</h2>
            <ul>
                {scores.map((score, index) => (
                    <li key={index}>
                        <Score
                            name={score.name}
                            score={score.score}
                            date={score.timestamp}
                            isVerified={score.isVerified} />
                    </li>
                ))}
            </ul>

            <button onClick={handleRefreshClick} className='refresh-button'>Oppdater klementiner</button>

        </div>
    );
};

export default HighScoreList;




