// src/AddScoreForm.js
import React, { useState } from 'react';
import '../css/AddScoreForm.css';
//import './AddScoreForm.css';

const AddScoreForm = () => {

    const [name, setName] = useState('');
    const [score, setScore] = useState('');
    const [message, setMessage] = useState('');
    const [showAdditionalInfo, setShowAdditionalInfo] = useState(false);
    const [size, setSize] = useState('');
    const [color, setColor] = useState('');
    const [sponginess, setSponginess] = useState('');
    const [sweetness, setSweetness] = useState('');
    const [seeds, setSeeds] = useState('');
    


    const handleSubmit = async (e) => {
        e.preventDefault();

        const functionUrl = ''; //TODO: Find proper url and key
        const functionKey = '';
        
        const formData = {
            name,
            score,
        };

        if (size) {
            formData.size = size;
        }
        if (color) {
            formData.color = color;
        }
        if (sponginess) {
            formData.sponginess = sponginess;
        }
        if (sweetness) {
            formData.sweetness = sweetness;
        }
        if (seeds) {
            formData.seeds = seeds; 
        }
        console.log(formData);


        try {
            const response = await fetch(functionUrl, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                    'x-functions-key': functionKey,  // Include the function key here
                },
                body: JSON.stringify(formData),
            });

            console.log(JSON.stringify(formData))
            if (response.ok) {
                setMessage('Suksess! Båtane har forlete hamna!');
                setName('');
                setScore('');
                setSize('');
                setColor('');
                setSponginess('');
                setSweetness('');
                setShowAdditionalInfo(false);
            } else {
                const errorData = await response.json();
                setMessage(`Error: ${errorData.error}`);
            }
        } catch (error) {
            setMessage(`Error: ${error.message}`);
        }
    };

    return (
        <div>
            <form className="add-score-form" onSubmit={handleSubmit}>
                <h2>Legg inn din klementin</h2>
                <div className="form-group">
                    <label>Kven sin klementin </label>
                    <input
                        type="text"
                        value={name}
                        onChange={(e) => setName(e.target.value)}
                        required
                    />
                </div>
                <div className="form-group">
                    <label>Antal båtar </label>
                    <input
                        type="number"
                        value={score}
                        onChange={(e) => setScore(e.target.value)}
                        required
                    />
                </div>
                <button
                    type="button"
                    className="toggle-additional-info"
                    onClick={() => setShowAdditionalInfo(!showAdditionalInfo)}>
                    {showAdditionalInfo ? '▴ Gøym' : '▾ Legg til meir info om klementinen'}
                </button>

                {showAdditionalInfo && (
                    <div className="additional-info">
                        <div className="form-group">
                            <label>Storleik</label>
                            <div className="radio-group">
                                <label>
                                    <input
                                        type="radio"
                                        value="Small"
                                        checked={size === 'Small'}
                                        onChange={(e) => setSize(e.target.value)}
                                    />
                                    Liten
                                </label>
                                <label>
                                    <input
                                        type="radio"
                                        value="medium"
                                        checked={size === 'medium'}
                                        onChange={(e) => setSize(e.target.value)}
                                    />
                                    Medium
                                </label>
                                <label>
                                    <input
                                        type="radio"
                                        value="large"
                                        checked={size === 'large'}
                                        onChange={(e) => setSize(e.target.value)}
                                    />
                                    Stor
                                </label>
                            </div>
                        </div>
                        <div className="form-group">
                            <label>Farge</label>
                            <div className="radio-group">
                                <label>
                                    <input
                                        type="radio"
                                        value="light"
                                        checked={color === 'light'}
                                        onChange={(e) => setColor(e.target.value)}
                                    />
                                    Lys 
                                </label>
                                <label>
                                    <input
                                        type="radio"
                                        value="normal"
                                        checked={color === 'normal'}
                                        onChange={(e) => setColor(e.target.value)}
                                    />
                                    Vanleg
                                </label>
                                <label>
                                    <input
                                        type="radio"
                                        value="dark"
                                        checked={color === 'dark'}
                                        onChange={(e) => setColor(e.target.value)}
                                    />
                                    Mørk
                                </label>
                            </div>
                        </div>
                        <div className="form-group">
                            <label>Mjukleik</label>
                            <div className="radio-group">
                                <label>
                                    <input
                                        type="radio"
                                        value="soft"
                                        checked={sponginess === 'soft'}
                                        onChange={(e) => setSponginess(e.target.value)}
                                    />
                                    Mjuk
                                </label>
                                <label>
                                    <input
                                        type="radio"
                                        value="normal"
                                        checked={sponginess === 'normal'}
                                        onChange={(e) => setSponginess(e.target.value)}
                                    />
                                    Vanleg
                                </label>
                                <label>
                                    <input
                                        type="radio"
                                        value="tight"
                                        checked={sponginess === 'tight'}
                                        onChange={(e) => setSponginess(e.target.value)}
                                    />
                                    Fast
                                </label>
                            </div>
                        </div>
                        <div className="form-group">
                            <label>Søtleik</label>
                            <div className="radio-group">
                                <label>
                                    <input
                                        type="radio"
                                        value="sweet"
                                        checked={sweetness === 'sweet'}
                                        onChange={(e) => setSweetness(e.target.value)}
                                    />
                                    Sur
                                </label>
                                <label>
                                    <input
                                        type="radio"
                                        value="normal"
                                        checked={sweetness === 'normal'}
                                        onChange={(e) => setSweetness(e.target.value)}
                                    />
                                    Vanleg
                                </label>
                                <label>
                                    <input
                                        type="radio"
                                        value="sour"
                                        checked={sweetness === 'sour'}
                                        onChange={(e) => setSweetness(e.target.value)}
                                    />
                                    Søt
                                </label>
                            </div>
                        </div>
                     
                        <div className="form-group-checkbox">
                            <label>Steinar i klementinen</label>
                            <input
                                type="checkbox"
                                value={color}
                                onChange={(e) => setSeeds(true)}
                            />
                        </div>
                    </div>
                )}

                <button type="submit">Kast loss!</button>
            </form>
            {message && <p>{message}</p>}
        </div>
    );
};

export default AddScoreForm;
