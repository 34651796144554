import React, { useEffect, useState } from 'react';
import giftsData from '../data/gifts.json'
import Quiz from './calendarComponents/Quiz'
import FunFact from './calendarComponents/FunFact'
import MusicPlayer from './calendarComponents/MusicPlayer';
//import './GiftText.css'; // Separate CSS for styling the text

const Gift = ({ day }) => {
    const [giftText, setGiftText] = useState('');

    const daytime = day ? day : 1;
    const giftData = giftsData[daytime]
    console.log(giftData);

    // Fetch the gift text from luke1.txt when the component mounts
    useEffect(() => {
        fetch('/luker/luke' + daytime + '.txt')
            .then((response) => response.text())
            .then((data) => {
                setGiftText(data); // Store the fetched text
            })
            .catch((error) => {
                console.error('Error fetching the text:', error);
            });
    }, [daytime]);

    switch (giftData?.type) {
        case 'quiz':
            return <Quiz data={giftData} />;
        case 'funfact':
            return <FunFact data={giftData} />
        case 'music':
            return <MusicPlayer day={daytime} />
        default:
            return (
                <div className="gift-text">
                    {giftText}
                </div>);
    }
};

export default Gift;
