import React, { useState, useEffect } from 'react';
import AdventGrid from './AdventGrid';
import AdventToday from './AdventToday.js';

const CalendarPage = () => {

    const date = new Date().getDay;
    const month = new Date().getMonth;

    const [selectedDay, setSelectedDay] = useState(date < 24 && month === 11 ? date : null);
    const [openedDays, setOpenedDays] = useState({}); // Stores the opened days



    useEffect(() => {

        const EXPIRY_TIME = 3600 * 1000; // 1 hour in milliseconds
        const isDataExpired = (timestamp) => {
            const now = new Date().getTime();
            return now - timestamp > EXPIRY_TIME;
        };

        const storedData = JSON.parse(localStorage.getItem('openedDaysData'));
        if (storedData) {
            const { timestamp, openedDays } = storedData;
            if (!isDataExpired(timestamp)) {
                setOpenedDays(openedDays);
            } else {
                localStorage.removeItem('openedDaysData'); // Remove expired data
            }
        }
    }, []);

    // Save opened days to localStorage whenever it changes, along with a timestamp
    useEffect(() => {
        const now = new Date().getTime();
        const dataToStore = {
            timestamp: now,
            openedDays
        };
        localStorage.setItem('openedDaysData', JSON.stringify(dataToStore));
    }, [openedDays]);

    // Handle when a day is clicked in the grid
    const handleDayClick = (day) => {
        // Switch to AdventToday component with the selected day
        setSelectedDay(day);
    };

    const handleBackClick = () => {
        setSelectedDay(null); // Go back to the grid
    };

    const handleGiftOpened = (day) => {
        const updatedOpenedDays = { ...openedDays, [day]: true };
        setOpenedDays(updatedOpenedDays);
        localStorage.setItem('openedDays', JSON.stringify(updatedOpenedDays));
    };


    return (
        <div>

            {selectedDay === null ? (
                <AdventGrid onDayClick={handleDayClick} />
            ) : (
                <AdventToday
                    day={selectedDay}
                    isOpened={openedDays[selectedDay] || false}
                    onGiftOpened={handleGiftOpened}
                    onBackClick={handleBackClick} />
            )}
            <div className="snowflakes" aria-hidden="true">
                {Array.from({ length: 20 }).map((_, index) => (
                    <div key={index} className="snowflake">❅</div>
                ))}
            </div>
        </div>
    );
};

export default CalendarPage;
